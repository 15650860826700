import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
// material
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from '../../../core/services/auth.service';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AlertService } from '../../../core/services/alert.service';
import { MatIconModule } from '@angular/material/icon';



@Component({
  selector: 'gc-login-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    RouterModule,
    MatIconModule
  ],
  templateUrl: './loginForm.component.html',
  styleUrl: './loginForm.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
  hidePassword: boolean = true;
  loginForm = new FormGroup({
    email: new FormControl<string>('', [
      Validators.required,
      Validators.email,
      Validators.minLength(6),
    ]),
    password: new FormControl<string>('', [Validators.required]),
  });

  ngOnInit(): void { }

  constructor(private auth: AuthService, private alert: AlertService, private router: Router) {
  }



  async onSubmit() {
    try {
      if (this.loginForm.invalid)
        return;

      const { email, password } = { ...this.loginForm.value } as any;
      const x = await this.auth.login({ email, password });
      console.log({ x });
      this.alert.success('Bienvenido!');
      const y = await this.router.navigate(['/dash/home']);
      console.log({ y });
    } catch (error: any) {
      if (error.code === 'user_not_found') {
        this.alert.error('Correo o contraseña incorrectos. Inténtalo de nuevo.');
        return;
      }

      this.alert.error('Ocurrío un error al iniciar sesión. Inténtalo de nuevo.');
      console.error(error);
    }
  }
}

import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const onlyAuthenticatedGuard: CanActivateFn = (route, state) => {
  const auth = inject<AuthService>(AuthService);
  console.log({
    route,
    state
  })
  if (auth.isAuthenticated()) {
    return true;
  }
  inject<Router>(Router).navigate(['/auth/login']);
  return false;

};

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { AuthLayoutComponent } from '../../components/layouts/authLayout/authLayout.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'gc-auth-page',
  standalone: true,
  imports: [
    CommonModule,
    AuthLayoutComponent,
    RouterModule
  ],
  templateUrl: './authPage.component.html',
  styleUrl: './authPage.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthPageComponent implements OnInit {

  ngOnInit(): void { }

}

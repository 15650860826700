<mat-card class="login-form">
  <mat-card-header>
    <mat-card-title class="p">Inicio de sesi&oacute;n</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="full-width input" appearance="outline">
        <mat-label>
          Correo electr&oacute;nico
        </mat-label>
        <input matInput placeholder="Email" formControlName="email" required>
        @if (loginForm.controls.email.hasError('required')){

        <mat-error>
          El correo electr&oacute;nico es requerido
        </mat-error>
        }
        @if (loginForm.controls.email.hasError('email')){

        <mat-error>
          El correo electr&oacute;nico no es v&aacute;lido
        </mat-error>
        }

      </mat-form-field>

      <mat-form-field class="full-width input" appearance="outline">
        <mat-label>
          Contraseña
        </mat-label>
        <input matInput placeholder="Password" formControlName="password" [type]="hidePassword?'password':'text'"
          required>
        @if (loginForm.controls.password.hasError('required')){
        <mat-error>
          La contrase&ntilde;a es requerida
        </mat-error>
        }
        <mat-icon matSuffix (click)="hidePassword = !hidePassword" [style.color]="hidePassword ? 'black' : 'gray'"
          [style.cursor]="'pointer'">
          {{
          hidePassword ? 'visibility_off' : 'visibility'
          }}
        </mat-icon>
      </mat-form-field>



      <!-- register -->
      <div class="register">
        <a [routerLink]="['/auth/register']">¿No tienes una cuenta? Reg&iacute;strate</a>
      </div>
      <br>
      <div class="btns">
        <button mat-raised-button color="primary" class="full-width" type="submit"
          [disabled]="loginForm.invalid">Iniciar
          sesi&oacute;n
        </button>
      </div>
    </form>
  </mat-card-content>
  <div class="forgot-password">
    <a [routerLink]="['/auth/reset-password']">¿Olvidaste tu contrase&ntilde;a?</a>
  </div>
</mat-card>

<!-- <h1>Prueba</h1> -->
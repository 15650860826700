import { Routes } from '@angular/router';
import { AuthPageComponent } from './pages/authPage/authPage.component';
import { LoginFormComponent } from './components/auth/loginForm/loginForm.component';
import { onlyAuthenticatedGuard } from './core/guards/onlyAuthenticated.guard';
import { noAuthenticatedGuard } from './core/guards/noAuthenticated.guard';
import { permissionsGuard } from './core/guards/permissions.guard';

export const routes: Routes = [
  {
    path: "auth",
    component: AuthPageComponent,
    canActivate: [noAuthenticatedGuard],
    children: [
      {
        path: "login",
        component: LoginFormComponent
      },
      {
        path: "register",
        loadComponent: () => import("./components/auth/register/register.component").then((m) => m.RegisterComponent),
      },
      {
        path: "validate-email",
        loadComponent: () => import("./components/auth/validateEmail/validateEmail.component").then((m) => m.ValidateEmailComponent),
      },
      {
        path: "reset-password",
        loadComponent: () => import("./components/auth/resetPassword/resetPassword.component").then((m) => m.ResetPasswordComponent),
      },
      {
        path: "verify-email",
        loadComponent: () => import("./components/auth/verifyEmail/verifyEmail.component").then((m) => m.VerifyEmailComponent),
      },
      {
        path: "invite",
        loadComponent: () => import("./components/auth/invite/invite.component").then((m) => m.InviteComponent),
      },
      {
        path: "**",
        redirectTo: "login",
      }
    ]
  }, {
    path: "dash",
    canActivate: [onlyAuthenticatedGuard],
    canActivateChild: [permissionsGuard],
    loadComponent: () => import("./components/layouts/dashboardLayout/dashboardLayout.component").then((m) => m.DashboardLayoutComponent),
    children: [
      {
        path: "home",
        loadComponent: () => import("./pages/home/home.component").then((m) => m.HomeComponent),
        data: {
          permissions: ["home.list"]
        }
      },
      {
        path: "users",

        loadComponent: () => import("./pages/usersPage/usersPage.component").then((m) => m.UsersPageComponent),
        data: {
          permissions: ["users.list"],
          title: "Usuarios"
        }
      },
      {
        path: "roles",
        loadComponent: () => import("./pages/rolesPage/rolesPage.component").then((m) => m.RolesPageComponent),
        data: {
          permissions: ["roles.list"],
          title: "Roles"
        }
      },
      {
        path: "settings",
        loadComponent: () => import("./pages/settingsPage/settingsPage.component").then((m) => m.SettingsPageComponent),
        data: {
          permissions: ["settings.list"],
          title: "Configuración"
        }
      },
      {
        path: "pages",
        loadComponent: () => import("./pages/pagePage/pagePage.component").then((m) => m.PagePageComponent),
        data: {
          permissions: ["pages.list"],
          title: "Páginas"
        }
      },
      {
        path: "profile",
        loadComponent: () => import("./pages/profilePage/profilePage.component").then((m) => m.ProfilePageComponent),
        data: {
          permissions: ["profile.list"],
          title: "Perfil"
        }
      },
      // Ecommerce Module
      {
        path: "store",
        loadComponent: () => import("./pages/storePage/storePage.component").then((m) => m.StorePageComponent),
        data: {
          permissions: ["store.list"],
          title: "Tienda y almacenes"
        }
      },
      {
        path: "catalogue",
        loadComponent: () => import("./pages/cataloguePage/cataloguePage.component").then((m) => m.CataloguePageComponent),
        data: {
          permissions: ["catalogue.list"],
          title: "Catálogo"
        }
      },
      {
        path: "inventory",
        loadComponent: () => import("./pages/inventory/inventory.component").then((m) => m.InventoryComponent),
        data: {
          permissions: ["inventory.list"],
          title: "Inventario"
        }
      },
      {
        path: "discount",
        loadComponent: () => import("./pages/discountPage/discountPage.component").then((m) => m.DiscountPageComponent),
        data: {
          permissions: ["discount.list"],
          title: "Descuentos"

        }
      },
      {
        path: "category",
        loadComponent: () => import("./pages/categoryPage/categoryPage.component").then((m) => m.CategoryPageComponent),
        data: {
          permissions: ["category.list"],
          title: "Categorías"
        }
      },
      {
        path: "menus",
        loadComponent: () => import("./pages/menuPage/menuPage.component").then((m) => m.MenuPageComponent),
        data: {
          permissions: ["menus.list"],
          title: "Menus"
        }
      },
      {
        path: "collections",
        loadComponent: () => import("./pages/collections-page/collections-page.component").then((m) => m.CollectionsPageComponent),
        data: {
          permissions: ["collections.list"],
          title: "Colecciones"
        }
      },
      {
        path: 'orders',
        loadComponent: () => import('./pages/orders-page/orders-page.component').then((m) => m.OrdersPageComponent),
        data: {
          permissions: ['orders.list'],
          title: 'Pedidos'
        }
      },

      {
        path: 'payment-methods',
        loadComponent: () => import('./pages/payment-methods-page/payment-methods-page.component').then((m) => m.PaymentMethodsPageComponent),
        data: {
          permissions: ['paymentMethods.list'],
          title: 'Métodos de pago'
        }
      },
      {
        path: 'transactions',
        loadComponent: () => import('./pages/transactions-page/transactions-page.component').then((m) => m.TransactionsPageComponent),
        data: {
          permissions: ['transactions.list'],
          title: 'Transacciones'
        }
      },
      {
        path: 'taxes',
        loadComponent: () => import('./pages/taxes-page/taxes-page.component').then((m) => m.TaxesPageComponent),
        data: {
          permissions: ['taxes.list'],
          title: 'Impuestos'
        }
      },
      {
        path: 'tax-rules',
        loadComponent: () => import('./pages/tax-rule-page/tax-rule-page.component').then((m) => m.TaxRulePageComponent),
        data: {
          permissions: ['taxRules.list'],
          title: 'Reglas de impuestos'
        }

      },
      {
        path: 'banners',
        loadComponent: () => import('./pages/banners-page/banners-page.component').then((m) => m.BannersPageComponent),
        data: {
          permissions: ['banner.list'],
          title: 'Banner'
        }
      },
      {
        path: 'widgets',
        loadComponent: () => import('./pages/widgets-page/widgets-page.component').then((m) => m.WidgetsPageComponent),
        data: {
          permissions: ['widgets.list'],
          title: 'Widgets'
        }
      },
      {
        path: 'seo',
        loadComponent: () => import('./pages/seo-page/seo-page.component').then((m) => m.SeoPageComponent),
        data: {
          permissions: ['seo.list'],
          title: 'SEO'
        }

      },
      {
        path: 'forms',
        loadComponent: () => import('./pages/forms-page/forms-page.component').then((m) => m.FormsPageComponent),
        data: {
          permissions: ['forms.list'],
          title: 'Formularios'
        }

      },

      // webhooks
      {
        path: 'webhooks',
        loadComponent: () => import('./pages/webhooks-page/webhooks-page.component').then((m) => m.WebhooksPageComponent),
        data: {
          permissions: ['webhooks.list'],
          title: 'Webhooks'
        }
      },
      // apis
      {
        path: 'api',
        loadComponent: () => import('./pages/apis-page/apis-page.component').then((m) => m.ApisPageComponent),
        data: {
          permissions: ['apis.list'],
          title: 'Apis'
        }
      },
      // apitokens
      {
        path: 'api-tokens',
        loadComponent: () => import('./pages/apitokens-page/apitokens-page.component').then((m) => m.ApitokensPageComponent),
        data: {
          permissions: ['apitokens.list'],
          title: 'Apitokens'
        }
      },
      //Google Analytics
      {
        path: 'google-analytics',
        loadComponent: () => import('./pages/gapage/gapage.component').then((m) => m.GAPageComponent),
        data: {
          permissions: ['googleAnalytics.list'],
          title: 'Google Analytics'
        }
      },
      // Facebook Pixel
      {
        path: 'facebook-pixel',
        loadComponent: () => import('./pages/fb-pixel-page/fb-pixel-page.component').then((m) => m.FbPixelPageComponent),
        data: {
          permissions: ['facebookPixel.list'],
          title: 'Facebook Pixel'
        }
      },
      // Google Tag Manager
      {
        path: 'google-tag-manager',
        loadComponent: () => import('./pages/gtmpage/gtmpage.component').then((m) => m.GTMPageComponent),
        data: {
          permissions: ['googleTagManager.list'],
          title: 'Google Tag Manager'
        }
      },
      // vars
      {
        path: 'vars',
        loadComponent: () => import('./pages/vars-page/vars-page.component').then((m) => m.VarsPageComponent),
        data: {
          permissions: ['variables.list'],
          title: 'Variables'
        }
      },


      {
        path: 'apps',
        loadComponent: () => import('./pages/apps-page/apps-page.component').then((m) => m.AppsPageComponent),
        data: {
          permissions: ['apps.list'],
          title: 'Apps'
        }
      },
      // currency
      {
        path: 'currency',
        loadComponent: () => import('./pages/currency-page/currency-page.component').then((m) => m.CurrencyPageComponent),
        data: {
          permissions: ['currency.list'],
          title: 'Monedas'
        }
      },
      //add product
      {
        path: 'product/add',
        loadComponent: () => import('./components/forms/productForm/productForm.component').then((m) => m.ProductFormComponent),
        data: {
          permissions: ['product.add'],
          title: 'Agregar producto'
        }
      },
      {
        path: '**',
        redirectTo: 'home'
      }

    ]
  },
  {
    path: "**",
    redirectTo: "auth",
  }
];

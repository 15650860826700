import { HttpEventType, type HttpErrorResponse, type HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, tap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const jsonInterceptor: HttpInterceptorFn = (req, next) => {
  const headers = req.headers.set('Content-Type', 'application/json');
  const jsonReq = req.clone({ headers });
  const auth = inject(AuthService)
  const router = inject(Router)
  return next(jsonReq).pipe(
    tap((event) => {
      if (event.type === HttpEventType.Response) {
      }
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        auth.closeSession();
        router.navigate(['/auth/login']);
      }
      return throwError({
        message: error.error.message,
        status: error.status,
        code: error.error.code
      });
    }))
};
